import React, { useEffect } from 'react';

const Timeline = () => {
  const timelineItems = [
    {
      number: 1,
      title: "Phase 1",
      text: "✅ Launch a mini-app with interactive AI dogs.\n◽️ Fair and decentralized token distribution.\n◽️ Reward early supporters with free tokens.",
    },
    {
      number: 2,
      title: "Phase 2",
      text: "◽️ Launch Beta AI Dogs platform with limited features.\n◽️ Improve AI dog interactivity and evolution.\n◽️ Develop an NFT marketplace for AI Dogs assets.",
    },
    {
      number: 3,
      title: "Phase 3",
      text: "◽️ Launch the full AI Dogs platform.\n◽️ Collaborate with AI and blockchain brands.",
    },
    {
      number: 4,
      title: "Phase 4",
      text: "◽️ Add features like NLP and fitness tracking.\n◽️ Enable user-created challenges and companions.\n◽️ Organize global events with rewards.",
    },
    {
      number: 5,
      title: "Phase 5",
      text: "◽️ Reach new global markets.\n◽️ Create self-learning AI companions.\n◽️ Introduce community-driven control.",
    },
  ];

  // Text animation effect for each phase (like typing animation)
  const textRevealAnimation = () => {
    const elements = document.querySelectorAll('.text-reveal');
    elements.forEach((element, index) => {
      setTimeout(() => {
        element.classList.add('animate-reveal');
      }, index * 300); // Stagger animation for each phase
    });
  };

  useEffect(() => {
    textRevealAnimation();
  }, []);

  return (
    <section id="timeline" className="py-16 text-white">
      <div className="relative flex flex-col items-center">
        {/* Timeline Container with Horizontal Scrolling */}
        <div className="w-full overflow-x-auto flex items-center justify-start space-x-8 relative z-10">
          {/* Timeline Items */}
          {timelineItems.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col items-center sm:w-56 md:w-64 lg:w-80 xl:w-96"
            >
              {/* Phase Block */}
              <div
                className="relative bg-[#2F0836] rounded-lg p-6 shadow-lg border-l-4 border-[#FEC95E] w-64 min-h-[300px] flex flex-col justify-between transform transition-all duration-300"
              >
                <div className="flex items-center justify-center mb-4">
                  <div
                    className="relative bg-[#FEC95E] rounded-full w-12 h-12 flex items-center justify-center text-[#2F0836] font-metavers font-bold text-2xl"
                  >
                    {item.number}
                  </div>
                </div>

                {/* Title */}
                <h3 className="text-[#FEC95E] font-metavers font-semibold text-2xl mb-2">{item.title}</h3>

                {/* Text */}
                <p className="text-white text-sm whitespace-pre-line text-reveal">{item.text}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Horizontal Connector Line */}
        <div className="absolute top-1/2 left-0 w-full h-1 bg-[#FEC95E] z-0" />
      </div>
    </section>
  );
};

export default Timeline;