import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logo } from "../Assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faWallet } from "@fortawesome/free-solid-svg-icons";
import CountdownTimer from "./CountdownTimer";

export const menuItems = [
  { title: "About", link: "about", isNav: false },
  { title: "Tokenomics", link: "tokenomics", isNav: false },
  { title: "PFP", link: "pfp", isNav: true },
  { title: "F.A.Q", link: "faq", isNav: false },
];

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const targetDate = new Date("2024-12-17T16:00:00Z"); // Set the target date

  // Function to handle scroll event
  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  // Function to handle link click
  const handleLinkClick = (link) => {
    if (location.pathname === '/' && document.getElementById(link)) {
      document.getElementById(link).scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      if (document.getElementById(link)) {
        navigate('/');
        setTimeout(() => {
          document.getElementById(link).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
    setIsMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* Navbar Section */}
      <nav
        className={`fixed top-0 left-0 w-full z-20 font-metavers transition-colors duration-300 ${
          isScrolled ? "bg-[#451A4D]" : "bg-transparent"
        }`}
      >
        <div className="container mx-auto flex justify-between items-center py-4 px-4 md:px-8">
          {/* Logo Section */}
          <div className="flex items-center space-x-3">
            <a href="/">
              <img src={logo} alt="Logo" className="h-12 w-auto md:h-16" />
            </a>
          </div>

          {/* Menu Items Section (Desktop) */}
          <div className="hidden md:flex space-x-12">
            {menuItems.map((item, index) => (
              <span
                key={index}
                onClick={() => {
                  if (item.isNav) {
                    navigate(`/${item.link}`)
                  } else {
                    handleLinkClick(item.link)
                  }
                }}
                className="text-white cursor-pointer hover:text-gray-400"
              >
                {item.title}
              </span>
            ))}
          </div>

          {/* Connect Button Section (Desktop) */}
          <button className="hidden md:flex items-center space-x-2 bg-white text-[#2F0836] py-2 px-4 rounded-lg hover:bg-gray-200 font-metavers">
            <FontAwesomeIcon icon={faWallet} className="text-[#2F0836]" />
            <span>Connect</span>
          </button>

          {/* Mobile Menu Toggle Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-white"
            >
              <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="lg" />
            </button>
          </div>
        </div>

        {/* Mobile Menu Dropdown */}
        {isMenuOpen && (
          <div className="md:hidden bg-[#451A4D] text-white text-center py-4 space-y-4">
            {menuItems.map((item, index) => (
              <span
                key={index}
                onClick={() => {
                  if (item.isNav) {
                    navigate(`/${item.link}`)
                  } else {
                    handleLinkClick(item.link)
                  }
                }}
                className="block cursor-pointer hover:text-gray-300"
              >
                {item.title}
              </span>
            ))}
            <button className="items-center space-x-2 bg-white text-[#2F0836] py-2 px-4 rounded-lg mx-auto hover:bg-gray-200 font-metavers">
              <span>Connect</span>
              <FontAwesomeIcon
                icon="fa-solid fa-wallet"
                className="text-[#2F0836]"
              />
            </button>
          </div>
        )}
              {/* Countdown Timer Section */}
      <div className="pb-4">
        <div className="container mx-auto">
          <CountdownTimer targetDate={targetDate} />
        </div>
      </div>
      </nav>


    </>
  );
};

export default Navbar;