import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons"; // Hamburger and Close icons
import {
  faXTwitter,
  faTelegram,
  faYoutube,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { logo } from "../Assets";

const NavbarPfp = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  return (
    <nav className="bg-[#2F0836] text-white px-6 py-4 flex items-center justify-between shadow-md fixed top-0 w-full z-50">
      {/* Logo/Image */}
      <Link to="/" className="flex items-center">
        <img src={logo} alt="AIDogs Logo" className="h-12 w-auto md:h-16" />
      </Link>

      {/* Title (Always Visible) */}
      <h1 className="text-lg md:text-xl font-metavers text-[#FEC95E]">
        $AIDOGS PFP Generator
      </h1>

      {/* Hamburger Menu for Small Screens */}
      <div className="md:hidden">
        <button
          onClick={toggleMenu}
          className="text-white text-2xl focus:outline-none"
        >
          <FontAwesomeIcon icon={isMenuOpen ? faXmark : faBars} />
        </button>

        {/* Dropdown Menu */}
        {isMenuOpen && (
          <div className="absolute right-6 top-16 bg-[#2F0836] text-white rounded-lg shadow-lg w-64 border border-[#FEC95E] p-4 space-y-4 z-50">
            {/* Social Icons */}
            <div className="flex justify-center space-x-4">
              <a
                href="https://x.com/AIDogscomm"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300"
              >
                <FontAwesomeIcon icon={faXTwitter} size="lg" />
              </a>
              <a
                href="https://t.me/aidogs_community"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300"
              >
                <FontAwesomeIcon icon={faTelegram} size="lg" />
              </a>
              <a
                href="https://www.youtube.com/@AIDogscomm"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300"
              >
                <FontAwesomeIcon icon={faYoutube} size="lg" />
              </a>
              <a
                href="https://www.instagram.com/aidogscomm?igsh=MWJzb2N6NWlidzJyNA=="
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
              <a
                href="https://www.tiktok.com/@aidogscomm?_t=8rnWiobxZMW&_r=1"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300"
              >
                <FontAwesomeIcon icon={faTiktok} size="lg" />
              </a>
            </div>

            {/* Buy Now Button */}
            <button className="w-full bg-[#FEC95E] text-[#2F0836] font-metavers py-2 px-4 rounded-lg hover:bg-[#ffd997] transition-colors duration-200">
              Buy Now
            </button>
          </div>
        )}
      </div>

      {/* Full Content (Hidden on Mobile) */}
      <div className="hidden md:flex items-center justify-between border border-[#FEC95E] rounded-lg py-2 px-6 w-full max-w-4xl">
        {/* Social Icons */}
        <div className="flex space-x-4">
          <a
            href="https://x.com/AIDogscomm"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faXTwitter} size="lg" />
          </a>
          <a
            href="https://t.me/aidogs_community"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faTelegram} size="lg" />
          </a>
          <a
            href="https://www.youtube.com/@AIDogscomm"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faYoutube} size="lg" />
          </a>
          <a
            href="https://www.instagram.com/aidogscomm?igsh=MWJzb2N6NWlidzJyNA=="
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
          <a
            href="https://www.tiktok.com/@aidogscomm?_t=8rnWiobxZMW&_r=1"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faTiktok} size="lg" />
          </a>
        </div>

        {/* Buy Now Button */}
        <button className="bg-[#FEC95E] text-[#2F0836] font-metavers py-2 px-4 rounded-lg hover:bg-[#ffd997] transition-colors duration-200">
          Buy Now
        </button>
      </div>
    </nav>
  );
};

export default NavbarPfp;