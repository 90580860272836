import React from 'react'
import { PfpGenerator } from '../Components'

const Pfp = () => {
  return (
    <div>
        <PfpGenerator/>
    </div>
  )
}

export default Pfp