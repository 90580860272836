import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faTelegram,
  faYoutube,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { logo } from "../Assets";
import { menuItems } from "./Navbar";

const Footer = () => {
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

  return (
    <footer className="text-white py-10 bg-[#2F0836] rounded-lg mx-4 md:mx-8 lg:mx-12">
      {/* Upper Section */}
      <div className="container mx-auto px-6 lg:px-12 grid grid-cols-1 md:grid-cols-5 gap-8 text-center md:text-left mb-8">
        {/* Logo and Brand Name */}
        <div className="flex items-center md:col-span-1 space-x-2 md:justify-start">
          <img src={logo} alt="AI Dogs Logo" className="h-12 w-auto" />
          <span className="text-3xl font-metavers">AI Dogs</span>
        </div>

        {/* Menu Items */}
        <div className="flex flex-col md:flex-row md:col-span-3 md:justify-center md:space-x-6 space-y-2 md:space-y-0">
          {menuItems.map((item, index) => (
            <a
              key={index}
              href={item.isNav ? `/${item.link}` : `../#${item.link}`}
              className="text-white hover:text-gray-300"
            >
              {item.title}
            </a>
          ))}
        </div>

        {/* Social Icons */}
        <div className="flex flex-wrap justify-center md:justify-start md:col-span-1 space-x-6 sm:space-x-4">
          <a
            href="https://x.com/AIDogscomm"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faXTwitter} size="lg" />
          </a>
          <a
            href="https://t.me/aidogs_community"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faTelegram} size="lg" />
          </a>
          <a
            href="https://www.youtube.com/@AIDogscomm"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faYoutube} size="lg" />
          </a>
          <a
            href="https://www.instagram.com/aidogscomm?igsh=MWJzb2N6NWlidzJyNA=="
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
          <a
            href="https://www.tiktok.com/@aidogscomm?_t=8rnWiobxZMW&_r=1"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <FontAwesomeIcon icon={faTiktok} size="lg" />
          </a>
        </div>
      </div>

      {/* Divider Line */}
      <div className="border-t border-gray-400 mb-4"></div>

      {/* Lower Section */}
      <div className="container mx-auto px-6 lg:px-12 flex flex-col md:flex-row justify-between items-center text-center md:text-left space-y-4 md:space-y-0">
        <span className="text-sm text-white">
          © {new Date().getFullYear()} AI Dogs. All rights reserved.
        </span>

        {/* Privacy Policy */}
        <button
          onClick={() => setIsPrivacyOpen(true)}
          className="text-sm text-white hover:text-gray-400 focus:outline-none"
        >
          Privacy Policy
        </button>

        {/* Launch Web-3 App */}
        <a
          href="https://t.me/AIDogs_bot"
          target="_blank"
          rel="noopener noreferrer"
          className="font-metavers text-[#FFE2A7] text-sm hover:text-yellow-300"
        >
          LAUNCH WEB-3 APP
        </a>
      </div>

      {/* Privacy Policy Modal */}
      {isPrivacyOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white text-[#2F0836] rounded-lg p-6 max-w-lg mx-auto">
            <h3 className="font-metavers text-lg font-semibold mb-4">
              Privacy Policy
            </h3>
            <p className="text-sm mb-4">
              At AI Dogs, we prioritize your privacy. We will never collect
              personal information. We use cookies to enhance your experience.
            </p>
            <p className="text-sm">
              AI Dogs Is Not Financial Advice. Our content is strictly for
              entertainment and informational purposes. We are not financial
              advisors, and no information on our platform should be considered
              financial advice. Always make your own financial decisions or
              consult a qualified advisor.
            </p>
            <button
              onClick={() => setIsPrivacyOpen(false)}
              className="mt-4 bg-[#2F0836] text-white px-4 py-2 rounded hover:bg-[#451A4D]"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;