import React from 'react';

const ImageTextSection = ({
  leftTitle,
  leftSubtitle,
  leftText,
  rightTitle,
  rightSubtitle,
  rightText,
  reverse = false, // Control layout direction
  bgColor = "#2F0836", // Background color customization
  buttonLink = null, // URL for the button link
  buttonText = "Start Your Adventure", // Text for the button
}) => {
  return (
    <section className={`w-full py-16`} style={{ backgroundColor: bgColor }}>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 items-center gap-8 max-w-7xl mx-auto px-6 md:px-12 lg:px-24 ${
          reverse ? "md:grid-flow-col-dense" : ""
        }`}
      >
        {/* Left Text Section */}
        <div className="bg-transparent border-l-4 border-[#FEC95E] p-6 flex flex-col items-start text-left">
          {/* Left Title */}
          <h3 className="text-xl font-metavers text-[#FEC95E] mb-2">
            {leftTitle}
          </h3>

          {/* Left Subtitle */}
          {leftSubtitle && (
            <h4 className="text-lg text-[#FEC95E] mb-4">{leftSubtitle}</h4>
          )}

          {/* Left Text Content */}
          <div className="space-y-2">
            {leftText.map((line, i) => (
              <p key={i} className="text-sm text-white">{line}</p>
            ))}
          </div>
        </div>

        {/* Right Text Section */}
        <div className="bg-transparent border-l-4 border-[#FEC95E] p-6 flex flex-col items-start text-left">
          {/* Right Title */}
          <h3 className="text-xl font-metavers text-[#FEC95E] mb-2">
            {rightTitle}
          </h3>

          {/* Right Subtitle */}
          {rightSubtitle && (
            <h4 className="text-lg text-[#FEC95E] mb-4">{rightSubtitle}</h4>
          )}

          {/* Right Text Content */}
          <div className="space-y-2">
            {rightText.map((line, i) => (
              <p key={i} className="text-sm text-white">{line}</p>
            ))}
          </div>

          {/* Optional Button */}
          {buttonLink && (
            <div className="mt-4">
              <a
                href={buttonLink}
                className="flex items-center space-x-2 bg-white text-[#2F0836] py-2 px-4 rounded-lg hover:bg-gray-200 font-metavers"
              >
                <span>{buttonText}</span>
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ImageTextSection;