import React, { useState, useRef } from "react";
import {
  accessory_1, accessory_2, accessory_3, accessory_4, accessory_5,
  base_1, base_2, base_3, base_4, base_5,
  blank,
  exp_1, exp_2, exp_3, exp_4, exp_5,
  hg_1, hg_2, hg_3, hg_4, hg_5,
  hg_6,
  hg_7,
  skin_1, skin_2, skin_3, skin_4, skin_5
} from "../Assets";

const PFPGenerator = () => {
  const layers = [
    { name: "Base", images: [base_1, base_2, base_3, base_4, base_5] },
    { name: "Skin", images: [skin_1, skin_2, skin_3, skin_4, skin_5] },
    { name: "Expressions", images: [exp_1, exp_2, exp_3, exp_4, exp_5] },
    { name: "Head Gear", images: [blank, hg_1, hg_2, hg_3, hg_4, hg_5, hg_6, hg_7] },
    { name: "Accessories", images: [blank, accessory_1, accessory_2, accessory_3, accessory_4, accessory_5] },
  ];

  const [selectedLayers, setSelectedLayers] = useState(
    layers.reduce((acc, layer) => {
      acc[layer.name] = layer.images[0];
      return acc;
    }, {})
  );

  const handleSelect = (layerName, image) => {
    setSelectedLayers((prevState) => ({
      ...prevState,
      [layerName]: image,
    }));
  };

  const scrollContainer = (ref, direction) => {
    if (ref.current) {
      const scrollAmount = ref.current.offsetWidth / 2; // Scroll half the width of the container
      ref.current.scrollLeft += direction === "left" ? -scrollAmount : scrollAmount;
    }
  };

  const scrollRefs = useRef(layers.map(() => React.createRef()));

  const generateRandomImage = () => {
    const randomLayers = layers.reduce((acc, layer) => {
      const randomImage = layer.images[Math.floor(Math.random() * layer.images.length)];
      acc[layer.name] = randomImage;
      return acc;
    }, {});
    setSelectedLayers(randomLayers);
  };

  const handleDownload = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = 2000;
    canvas.height = 2000;

    layers.forEach((layer) => {
      const img = new Image();
      img.src = selectedLayers[layer.name];
      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    });

    const downloadImage = () => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "aidogs-pfp-generator.png";
      link.click();
    };

    setTimeout(downloadImage, 500);
  };

  return (
    <div className="min-h-screen flex flex-col lg:flex-row items-start lg:items-stretch gap-4 p-4 md:p-12 mt-20">
      {/* Left Side */}
      <div className="w-full lg:w-1/3 border border-[#FEC95E] p-4 rounded-lg flex flex-col items-center justify-center">
        <div className="relative w-64 h-64 bg-gray-700 rounded-lg mb-4 overflow-hidden">
          {layers.map((layer, index) => (
            <img
              key={index}
              src={selectedLayers[layer.name]}
              alt={layer.name}
              className="absolute inset-0 w-full h-full"
              style={{ zIndex: index }}
            />
          ))}
        </div>
        <button
          className="w-full xl:w-2/3 bg-[#FEC95E] text-[#2F0836] py-2 rounded-lg font-metavers hover:bg-[#ffd997] transition-colors duration-200 mb-2"
          onClick={generateRandomImage}
        >
          Generate Random
        </button>
        <button
          className="w-full xl:w-2/3 bg-[#FEC95E] text-[#2F0836] py-2 rounded-lg font-metavers hover:bg-[#ffd997] transition-colors duration-200 mb-2"
          onClick={() =>
            setSelectedLayers(
              layers.reduce((acc, layer) => {
                acc[layer.name] = layer.images[0];
                return acc;
              }, {})
            )
          }
        >
          Reset
        </button>
        <button
          className="w-full xl:w-2/3 bg-[#FEC95E] text-[#2F0836] py-2 rounded-lg font-metavers hover:bg-[#ffd997] transition-colors duration-200 mb-2"
          onClick={handleDownload}
        >
          Download
        </button>
      </div>

      {/* Right Side */}
      <div className="w-full lg:w-2/3 border border-[#FEC95E] p-4 md:p-6 rounded-lg overflow-y-auto" style={{ maxHeight: "calc(100vh - 100px)" }}>
        {layers.map((layer, index) => (
          <div key={index} className="mb-6">
            <h3 className="text-xl font-bold text-[#FEC95E] mb-2">{layer.name}</h3>
            <div className="relative flex items-center">
              {/* Scroll Buttons */}
              <button
                className="absolute left-0 z-10 bg-[#2F0836] text-white p-2 rounded-full hover:bg-[#FEC95E] transition-colors"
                onClick={() => scrollContainer(scrollRefs.current[index], "left")}
              >
                ◀
              </button>
              <div
                ref={scrollRefs.current[index]}
                className="flex overflow-x-scroll no-scrollbar space-x-4 px-8"
                style={{
                  width: "calc(100% - 4rem)", // Account for buttons' width
                }}
              >
                {/*layer.images.map((image, i) => (
                  <div
                    key={i}
                    className={`min-w-[80px] h-20 bg-white/30 rounded-lg flex items-center justify-center cursor-pointer ${
                      selectedLayers[layer.name] === image ? "ring-4 ring-[#FEC95E]" : ""
                    }`}
                    onClick={() => handleSelect(layer.name, image)}
                  >
                    <img
                      src={image}
                      alt={layer.name}
                      className="max-w-full max-h-full object-contain"
                    />
                  </div>                              
                ))*/}
                {layer.images.map((image, i) => (
                  <div
                    key={i}
                    className={`min-w-[80px] h-20 bg-white/30 rounded-lg flex items-center justify-center cursor-pointer ${
                      selectedLayers[layer.name] === image ? "ring-4 ring-[#FEC95E]" : ""
                    }`}
                    style={{
                      backgroundImage: `url(${image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={() => handleSelect(layer.name, image)}
                  />
                ))}
              </div>
              <button
                className="absolute right-0 z-10 bg-[#2F0836] text-white p-2 rounded-full hover:bg-[#FEC95E] transition-colors"
                onClick={() => scrollContainer(scrollRefs.current[index], "right")}
              >
                ▶
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PFPGenerator;