import React from "react";
import { motion } from "framer-motion"; // Import Framer Motion
import { kf1, kf2, kf3, kf4, kf5, kf6 } from "../Assets"; // Ensure correct paths for images

const KeyFeatures = () => {
  const features = [
    {
      title: "AI-Powered Companions",
      image: kf1,
      text: "Train and evolve your AI Dogs using cutting-edge machine learning. Each interaction shapes their personality, making your AI Dogs truly one of a kind. From playful and loyal to mischievous and brave, the possibilities are endless.",
    },
    {
      title: "Gamified Missions",
      image: kf2,
      text: "Embark on exciting and rewarding adventures with your AI Dogs! Take on challenges that combine fitness, trivia, and creativity, earning exclusive prizes as you play, learn, and compete in a fun, interactive way.",
    },
    {
      title: "Marketplace and NFTs",
      image: kf3,
      text: "Discover endless possibilities in our blockchain-powered marketplace! Customize, trade, and breed your AI Dogs to create rare and valuable companions, all while exploring unique NFTs and exclusive accessories.",
    },
    {
      title: "Social Engagement",
      image: kf4,
      text: "Join a vibrant community and interact with other users and their AiDogs in dynamic virtual spaces! Team up for challenges, compete in exciting events, and share your AI Dog’s journey across social platforms.",
    },
    {
      title: "Smart Blockchain Utilities",
      image: kf5,
      text: "AI Dogs provide real-time market insights, AI-curated trading tips, and activity-based rewards like SOL, seamlessly blending intelligence with blockchain functionality",
    },
    {
      title: "Telegram Mini-App",
      image: kf6,
      text: "Instant access to your AI Dogs for games, missions, and social engagement directly within Telegram.",
    },
  ];

  return (
    <div>
      <section id="key-features" className="py-16">
        {/* Section Title */}
        <motion.div
          className="text-center mb-12"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-3xl md:text-4xl font-metavers text-[#FEC95E]">
            Key Features
          </h2>
        </motion.div>

        {/* Features Flexbox */}
        <div className="flex flex-wrap justify-center gap-8 px-6 md:px-12 lg:px-24 mx-auto ">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="bg-transparent border border-[#FEC95E] rounded-2xl shadow-md flex flex-col items-start text-left p-6 w-full sm:w-[calc(50%-1rem)] lg:w-[calc(33.333%-2rem)]"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.6,
                delay: index * 0.2,
              }}
            >
              {/* Feature Image */}
              <motion.img
                src={feature.image}
                alt={feature.title}
                className="h-12 object-cover rounded-lg mb-4"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              />
              {/* Feature Title */}
              <motion.h3
                className="text-xl font-metavers text-[#FEC95E] mb-2 "
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                {feature.title}
              </motion.h3>
              {/* Feature Text */}
              <motion.p
                className="text-white whitespace-pre-line"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.4 }}
              >
                {feature.text}
              </motion.p>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default KeyFeatures;