import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft(null); // Countdown finished
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  if (!timeLeft) {
    return <div className="text-center text-xl font-bold text-[#FEC95E]">Event Started!</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="text-center font-metavers text-lg md:text-2xl font-semibold text-[#FEC95E] mb-4">
        AIDOGS Listing on Raydium in
      </div>
      <div className="flex space-x-4 justify-center text-white font-metavers">
        <div className="flex flex-col items-center">
          <span className="text-2xl md:text-3xl lg:text-4xl font-metavers">{timeLeft.days}</span>
          <span className="text-sm">Days</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-2xl md:text-3xl lg:text-4xl font-metavers">{timeLeft.hours}</span>
          <span className="text-sm">Hours</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-2xl md:text-3xl lg:text-4xl font-metavers">{timeLeft.minutes}</span>
          <span className="text-sm">Minutes</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-2xl md:text-3xl lg:text-4xl font-metavers">{timeLeft.seconds}</span>
          <span className="text-sm">Seconds</span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;