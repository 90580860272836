import React from "react";
import { motion } from "framer-motion";

const InfoSections = () => {
  const features = [

    {
      title: "Gamified AI Missions",
      subtitle: "Where Fun Meets Technology",
      text: [
        "⚡️ Daily Tasks: Quick and fun challenges to keep you engaged.",
        "⚡️ Interactive Campaigns: Coordinate with other users and AI agents in large-scale operations.",
        "⚡️ AI-Generated Challenges: Missions evolve dynamically based on user actions and AI decisions.",
      ],
    },
    {
      title: "AI-Driven Technology at Work",
      subtitle: "The AI Dogs platform utilizes a groundbreaking AI swarm system, combining human creativity and AI adaptability to redefine digital interactions.",
      text: [
        "⚡️ Advanced Mission Algorithms: AI dynamically creates missions and adjusts difficulty for an optimized experience.",
        "⚡️ Decentralized Decision-Making: Your contributions shape the direction of the ecosystem, empowering users through token-based governance.",
        "⚡️ AI-Enhanced Creativity: Your AI Dogs collaborates with you to generate unique content, from memes to narrative elements.",
      ],
    },
  ];

  return (
    <section id="key-features" className="py-16 text-white">
      {/* Section Title */}
      <motion.div
        className="text-center mb-12"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >

      </motion.div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8  mx-auto">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className="bg-[#2F0836] border-l-4 border-[#FEC95E] rounded-2xl p-6 flex flex-col items-start text-left w-full"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.6,
              delay: index * 0.2,
            }}
          >
            {/* Feature Title */}
            <motion.h3
              className="text-xl font-metavers text-[#FEC95E] mb-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              {feature.title}
            </motion.h3>

            {/* Feature Subtitle */}
            {feature.subtitle && (
              <motion.h4
                className="text-lg text-[#FEC95E] mb-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                {feature.subtitle}
              </motion.h4>
            )}

            {/* Feature Text */}
            <motion.div
              className="space-y-2"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.5 }}
            >
              {feature.text.map((line, i) => (
                <p key={i} className="text-sm">{line}</p>
              ))}
            </motion.div>

            {/* Button for additional information */}
            {feature.linkText && (
              <motion.div
                className="mt-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                <button className="hidden md:flex items-center space-x-2 bg-white text-[#2F0836] py-2 px-4 rounded-lg hover:bg-gray-200 font-metavers">
                  <span>{feature.linkText}</span>
                </button>
              </motion.div>
            )}
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default InfoSections;