import React from "react";
import { motion } from "framer-motion";
import {
  heroBackground,
  heroImage,
  solanabg,
  tokenomicsImage,
  unleashbg,
  unleashbg2,
} from "../Assets";
import {
  FAQ,
  ImageTextSection,
  InfoSections,
  KeyFeatures,
  Timeline,
} from "../Components";
import {
  faInstagram,
  faTelegram,
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = () => {
  // Statistics data
  const stats = [
    { title: "TG Mini-app users", value: "4M+" },
    { title: "TG Subs", value: "1.28M" },
    { title: "X Followers", value: "270k" },
    { title: "IG Followers", value: "227k" },
    { title: "Youtube Subs", value: "200k" },
    { title: "TikTok Followers", value: "44k" },
  ];


  return (
    <div className="text-white">
      {/* Hero Section */}
{/* Hero Section */}
<motion.section
        id="hero"
        className="lg:min-h-screen w-full relative flex flex-col md:flex-row items-center justify-between text-white pt-[250px]"
        style={{
          backgroundImage: `url(${heroBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >


        {/* Left Column: Text and Button */}
        <motion.div
          className="flex flex-col items-start space-y-4 md:space-y-6 px-6 md:px-12 lg:px-24"
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="bg-[#FEC95E] text-[#2F0836] rounded-full px-4 py-2 text-sm font-semibold">
            4M+ Players
          </div>
          <h1 className="text-3xl md:text-5xl font-metavers font-bold">
            Welcome to AI Dogs
          </h1>
          <p className="mt-4 max-w-xl">
            Meme-inspired AI companions on Solana with Telegram mini-app for
            interactive fun and rewards.
          </p>
          {/* Social Icons */}
                  {/* Social Icons */}
                  <div className="flex justify-center md:justify-start md:col-span-1 space-x-6">
          <a href="https://x.com/AIDogscomm" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
            <FontAwesomeIcon icon={faXTwitter} size="lg" />
          </a>
          <a href="https://t.me/aidogs_community" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
            <FontAwesomeIcon icon={faTelegram} size="lg" />
          </a>
          <a href="https://www.youtube.com/@AIDogscomm" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
            <FontAwesomeIcon icon={faYoutube} size="lg" />
          </a>
          <a href="https://www.instagram.com/aidogscomm?igsh=MWJzb2N6NWlidzJyNA==" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
          <a href="https://www.tiktok.com/@aidogscomm?_t=8rnWiobxZMW&_r=1" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
            <FontAwesomeIcon icon={faTiktok} size="lg" />
          </a>
        </div>
          <a
            href="https://aidogs.gitbook.io/aidogs-whitepaper"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4 bg-[#FEC95E]/0 text-[#FEC95E] px-6 py-3 rounded-lg border border-[#FEC95E] font-metavers text-lg hover:bg-[#ffd997] transition-colors duration-200"
          >
            White Paper
          </a>
        </motion.div>

        {/* Hero Image */}
        <motion.img
          src={heroImage}
          alt="Hero Center"
          className="w-3/4 sm:w-1/2 md:w-2/5 lg:w-1/2 h-auto object-contain order-last lg:order-none mt-8 lg:mt-0"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
        />
      </motion.section>

      {/* About Us Section */}
      <section
        className="flex flex-col md:flex-row items-center py-24 px-2"
        id="about"
      >
        <motion.div
          className="max-w-5xl mx-auto md:pl-10 flex flex-col items-center"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h2 className="text-3xl md:text-4xl font-metavers text-[#FEC95E]">
            About Us
          </h2>
          <p className="mt-4 text-center">
            AI Dogs has onboarded over 4 million dawgs into crypto through
            interactive experiences. Now, with AI DOGS, we’re combining AI, meme
            culture, and gamification to let you train, evolve, and compete with
            personalized AI Dogs while shaping the future of on-chain
            communities.
          </p>

          {/* Statistics */}
          <div className="mt-12 max-w-4xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                className="bg-[#2F0836] border border-[#FEC95E] p-6 rounded-lg text-center"
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <h3 className="text-[#FEC95E]">{stat.title}</h3>
                <p className="text-3xl font-metavers mt-2 text-white">
                  {stat.value}
                </p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>

      {/* Why Solana Section */}
      <motion.section
        id="why-solana"
        className="relative bg-cover bg-top text-white rounded-2xl shadow-lg overflow-hidden mx-4 md:mx-8 lg:mx-16 my-12 p-8 md:p-12 lg:p-16 flex flex-col items-center justify-center"
        style={{
          backgroundImage: `url(${solanabg})`, // Use your desired background image here
        }}
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.7 }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/85 to-black/80 z-0 border border-[#FEC95E]"></div>
        <div className="relative z-10 text-center max-w-3xl">
          <motion.h2
            className="text-3xl md:text-4xl font-metavers text-[#FEC95E]"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Why Solana?
          </motion.h2>
          <motion.p
            className=" m-6  text-white"
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            Solana’s unmatched speed, low fees, and vibrant community make it
            the perfect home for AI Dogs. By launching on Solana, we’re
            unlocking tangible rewards, liquidity, and an unparalleled user
            experience for our community.
          </motion.p>
        </div>
      </motion.section>

      {/* Key Features */}
      <KeyFeatures />

      {/* CTA Section 2*/}
      <motion.section
        id="cta"
        className="relative bg-cover bg-center text-white rounded-2xl shadow-lg overflow-hidden mx-4 md:mx-8 lg:mx-16 my-12 p-8 md:p-12 lg:p-16 flex flex-col items-center justify-center"
        style={{
          backgroundImage: `url(${unleashbg})`,
        }}
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.7 }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/85 to-black/80 z-0 border border-[#FEC95E]"></div>
        <div className="relative z-10 text-center  flex flex-col items-center">
          <motion.h2
            className="text-3xl md:text-4xl font-metavers text-[#FEC95E]"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            The Power of AI DOGS Tokens
          </motion.h2>
          <motion.p
            className="text-lg md:text-xl m-6 font-bold"
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            Fueling the Ai Dogs Ecosystem
          </motion.p>
          <motion.p
            className="  "
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            ⚡️ Earn Rewards: Participate in daily missions, meme creation
            contests, and gamified challenges. <br />
            ⚡️ Stake for Growth: Unlock exclusive perks, enhanced AI
            capabilities, and early access to features.
            <br />
            ⚡️ Dynamic NFTs: Personalize and trade unique traits, skins, and
            accessories for your AiDog.
          </motion.p>
          {/* Info Section */}
          <InfoSections />
          <motion.div
            className="mt-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6 }}
          ></motion.div>
        </div>
      </motion.section>

      {/* Tokenomics Section */}
      <motion.section
        id="tokenomics"
        className="py-20 text-center"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-4xl md:text-5xl font-metavers text-[#FEC95E]">
          Tokenomics
        </h2>
        <p className="mt-4 max-w-xl mx-auto">Max Supply: 1,000,000,000</p>
        <div className="flex justify-center mt-8">
          <div className="flex flex-wrap justify-center space-x-6">
            {[
              { percentage: "80%", label: "Airdrop & Community Incentives" },
              { percentage: "15%", label: "Liquidity Pool & Staking" },
              { percentage: "5%", label: "Reserves & Partnerships" },
            ].map((item, index) => (
              <motion.div
                key={index}
                className="flex flex-col items-center mb-8"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="w-20 h-20 font-metavers flex items-center justify-center rounded-full bg-transparent md:p-20 border border-[#FEC95E] text-white text-2xl md:text-4xl font-bold">
                  {item.percentage}
                </div>
                <p className="text-sm text-[#FEC95E] mt-2">{item.label}</p>
              </motion.div>
            ))}
          </div>
        </div>

        <img
          src={tokenomicsImage}
          alt="Tokenomics"
          className="mt-8 w-full object-cover h-64 md:h-full"
        />
      </motion.section>

      {/* Feature 1*/}
      <ImageTextSection
        leftTitle="The AI Engine Behind AI Dogs"
        leftSubtitle="At the heart of AI Dogs is a proprietary AI system designed to create lifelike interactions, immersive experiences, and dynamic growth."
        leftText={[
          "⚡️ Natural Language Understanding (NLU): Communicate with your AI Dogs as it adapts to your personality and preferences.",
          "⚡️ Computer Vision: Unlock unique traits as your AI Dogs learns from visual inputs like meme designs and user-generated content.",
          "⚡️ Adaptive Intelligence: Your AI Dogs evolves through missions and tasks, growing smarter and more customized over time.",
        ]}
        rightTitle="A Living, Breathing Meme World"
        rightSubtitle="Your AI Dogs isn’t just a virtual pet—it’s an active participant in a shared, ever-changing meme universe."
        rightText={[
          "⚡️ Create Viral Memes: Collaborate with your AI Dogs to design and share viral meme content.",
          "⚡️ Dynamic Storylines: Every action contributes to an evolving narrative shaped by the community and AI.",
          "⚡️ Collaborative World-Building: Join forces with other users to create, explore, and expand the AI Dogs universe.",
        ]}
        bgColor="#2F0836"
      />

      {/* Roadmap Section */}
      <motion.section
        id="roadmap"
        className="py-20 text-center"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-4xl md:text-5xl font-metavers text-[#FEC95E]">
          Roadmap
        </h2>
        <p className="mt-4 max-w-xl mx-auto">
          Our roadmap outlines AI Dogs’ journey from foundational community
          building and token launch to governance through the Dawgs DAO.
        </p>
        <Timeline />
      </motion.section>

      {/* CTA Section 4*/}
      <motion.section
        id="cta"
        className="relative bg-cover bg-center text-white rounded-2xl shadow-lg overflow-hidden mx-4 md:mx-8 lg:mx-16 my-12 p-8 md:p-12 lg:p-16 flex flex-col items-center justify-center"
        style={{
          backgroundImage: `url(${unleashbg2})`,
        }}
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.7 }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/85 to-black/80 z-0 border border-[#FEC95E]"></div>
        <div className="relative z-10 text-center max-w-6xl flex flex-col items-center">
          <motion.h2
            className="text-3xl md:text-4xl font-metavers text-[#FEC95E]"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Join the Meme Revolution
          </motion.h2>
          <motion.p
            className="text-lg md:text-xl m-6 font-bold"
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            AI Dogs isn’t just a platform; it’s a movement. Become part of a
            global network of meme creators, AI enthusiasts, and blockchain
            pioneers reshaping digital culture.
          </motion.p>
          <motion.p
            className="  "
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            ⚡️ Engage: Participate in gamified challenges and contests.
            <br />
            ⚡️ Earn: Build your rewards through missions, trades, and staking.
            <br />
            ⚡️ Evolve: Watch your AiDog grow smarter, more unique, and more
            valuable over time.
          </motion.p>
        </div>
      </motion.section>

      {/* FAQ */}
      <FAQ />
    </div>
  );
};

export default Home;
