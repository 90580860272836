import React, { useState } from 'react';
import { motion } from 'framer-motion';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: 'What is AI Dogs?',
      answer: 'AI Dogs is an AI-powered gaming platform that combines blockchain technology, advanced AI, and gamification to create virtual dog companions. You can train, customize, and compete with your AI Dogs while earning rewards and contributing to a vibrant community of token holders.',
    },
    {
      question: 'How do AI-powered companions work?',
      answer: 'AI Dogs use cutting-edge artificial intelligence, including natural language processing, adaptive learning, and generative AI. These technologies allow your AI Dogs to evolve its personality based on your interactions, respond to voice commands, and participate in dynamic missions and challenges.',
    },
    {
      question: 'What are the benefits of being a token holder?',
      answer: (
        <div>
          As an AI Dogs token holder, you enjoy exclusive benefits such as:
          <ul className="list-disc pl-5 mt-2">
            <li>Early access to new platform features.</li>
            <li>Voting rights to influence platform development and updates.</li>
            <li>Participation in revenue-sharing and staking opportunities.</li>
            <li>Access to advanced mission tools and rewards.</li>
          </ul>
        </div>
      ),
    },
    {
      question: 'How does the marketplace function?',
      answer: 'The AI Dogs marketplace lets users trade, customize, and breed their virtual companions. Traits, accessories, and unique offspring can be minted as NFTs, bought, or sold on the blockchain. This system ensures a secure, transparent, and decentralized trading environment.',
    },
    {
      question: 'What’s next for AI Dogs?',
      answer: (
        <div>
          AI Dogs is continuously evolving. Future plans include:
          <ul className="list-disc pl-5 mt-2">
            <li>Expanding collaborative missions and multiplayer competitions.</li>
            <li>Integrating advanced AI tools for personalized user experiences.</li>
            <li>Launching cross-chain compatibility to bring AI Dogs to other blockchain ecosystems.</li>
            <li>Introducing more features for token holders and community-driven storytelling.</li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <div className="text-white py-10 px-4 max-w-2xl mx-auto" id="faq">
      <h2 className="text-3xl md:text-4xl font-metavers text-[#FEC95E] mb-8 text-center">FAQ</h2>
      {faqItems.map((item, index) => (
        <div key={index} className="mb-4">
          <div
            className="cursor-pointer flex justify-between items-center bg-[#451A4D] p-4 rounded-lg"
            onClick={() => toggleAccordion(index)}
          >
            <h3 className="text-md">{item.question}</h3>
            <span className="text-2xl">{activeIndex === index ? '-' : '+'}</span>
          </div>

          {activeIndex === index && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              transition={{ duration: 0.3 }}
              className="bg-[#71307D] p-4 rounded-b-lg text-white"
            >
              <p className="text-sm">{item.answer}</p>
            </motion.div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;