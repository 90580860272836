import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { FloatButton } from "antd";
import { Footer, Navbar, NavbarPfp, PoweredBySui } from "./Components"; // Import NavbarPfp
import { Home, NoPage, Pfp } from "./Pages";

function App() {
  // Use the `useLocation` hook to determine the current path
  const location = useLocation();

  // Determine if the current route is /pfp
  const isPfpRoute = location.pathname === "/pfp";

  return (
    <div className="App scroll-smooth bg-gradient-to-b from-[#0F0011] via-[#451A4D] to-[#71307D] py-4 md:py-6">
      {/* Conditionally render Navbar or NavbarPfp */}
      {isPfpRoute ? <NavbarPfp /> : <Navbar />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<NoPage />} />
        <Route path="/pfp" element={<Pfp />} />
      </Routes>

      <Footer />
      <FloatButton.BackTop />
      <PoweredBySui />
    </div>
  );
}

// Wrap App with BrowserRouter to provide location context
function AppWithRouter() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWithRouter;